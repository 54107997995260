import React from "react";

export default function Sigil({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 544 537"
      className={className}
      aria-hidden="true"
    >
      <g stroke="currentColor" strokeWidth="3" fill="none" fillRule="evenodd">
        <path d="M542.5 1.5v110.161l-25.352 21.995-110.005 95.437-247.81 245.794-93.922-93.196 250.34-248.402H1.5V1.5h541Z" />
        <path d="m412.5 228.35 129.997 116.08.003 152.559-.003 38.511H412.5V228.35Z" />
      </g>
    </svg>
  );
}
