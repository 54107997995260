import React from "react";
import Body from "components/Body";
import Scribbles1 from "components/SVG/Scribble/Scribbles1";
import Scribbles2 from "components/SVG/Scribble/Scribbles2";
import Sigil from "components/SVG/Sigil";
import classNames from "lib/classNames";
import Image from "next/image";
import { m } from "framer-motion";
import Headings from "components/Headings";
import { fadeIn, fadeUp } from "lib/variants";

export default function IntroSection({ intro, image = null }) {
  return (
    <m.section variants={fadeIn} className="relative pt-16 lg:py-16 2xl:py-28">
      <div className="relative container px-4 lg:px-12 mx-auto">
        <Headings
          pretitle={intro.field_title_alt}
          title={intro.field_title}
          scribble={
            <Scribbles1 className="absolute top-1/2 left-0 -translate-y-1/2 w-1/3 md:w-[13vw] text-primary" />
          }
        />
        <div
          className={classNames(
            "relative grid grid-cols-1 gap-12 mt-12 2xl:mt-20",
            image && "lg:grid-cols-2"
          )}
        >
          <div className="flex flex-col justify-center z-10">
            <m.div
              variants={fadeUp}
              initial="out"
              whileInView="enter"
              className="prose prose-dark will-change-transform"
            >
              <Body value={intro.body.processed} />
            </m.div>
          </div>
          {image && (
            <div className="flex flex-col justify-center">
              <m.div
                variants={fadeUp}
                initial="out"
                whileInView="enter"
                className="-mx-4 md:mx-0 will-change-transform"
              >
                <div className="relative aspect-w-8 aspect-h-5">
                  <figure>
                    <Scribbles2 className="hidden lg:block absolute bottom-0 left-0 -translate-x-1/2 translate-y-1/3 w-[11.14vw] text-primary" />
                    <Image
                      src={image.field_image.image_style_uri.large}
                      alt={image.field_image.resourceIdObjMeta.alt}
                      className="lg:rounded-3xl overflow-hidden"
                      fill
                    />
                  </figure>
                </div>
              </m.div>
            </div>
          )}
        </div>
      </div>
      {image && (
        <Sigil className="hidden lg:block lg:absolute top-1/3 right-0 -translate-y-1/3 w-[26vw] text-primary" />
      )}
    </m.section>
  );
}
